import React from 'react';
import './Pagination.css';
import { Link } from 'gatsby';

const Pagination = ({ currentPage, totalPages }) => {
  if (totalPages <= 1) return null; // Oculta a paginação se houver apenas uma página

  const isFirst = currentPage === 1;
  const isLast = currentPage === totalPages;

  const prevPage = currentPage - 1 === 1 ? '/blog' : `/blog/page/${currentPage - 1}`;
  const nextPage = `/blog/page/${currentPage + 1}`;

  return (
    <nav className="pagination" aria-label="Paginação de posts">
      {!isFirst && (
        <Link to={prevPage} rel="prev" className="prev-page" aria-label="Página anterior">
          ← Página Anterior
        </Link>
      )}
      {Array.from({ length: totalPages }, (_, i) => (
        <Link
          key={`pagination-number${i + 1}`}
          to={`/blog/page/${i + 1}`}
          className={i + 1 === currentPage ? 'active' : ''}
          aria-current={i + 1 === currentPage ? 'page' : undefined}
        >
          {i + 1}
        </Link>
      ))}
      {!isLast && (
        <Link to={nextPage} rel="next" className="next-page" aria-label="Próxima página">
          Próxima Página →
        </Link>
      )}
    </nav>
  );
};

export default Pagination;
