import React from 'react';
import './BlogCategoryHeader.css';

const BlogCategoryHeader = ({ categoryName = 'Categoria Desconhecida' }) => {
  return (
    <header className="blog-category-header">
      <h1 className="category-title" aria-label={`Categoria: ${categoryName}`}>
        Categoria: {categoryName}
      </h1>
    </header>
  );
};

export default BlogCategoryHeader;
