import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PostCard from '../PostCard/postCard';
import './PostList.css';

const PostList = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityPost(sort: { fields: publishedAt, order: DESC }) {
        edges {
          node {
            id
            title
            slug {
              current
            }
            excerpt
            mainImage {
              asset {
                url
              }
            }
            publishedAt(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  `);

  const posts = data?.allSanityPost?.edges || [];

  return (
    <div className="post-list-container">
      {posts.map(({ node }) => (
        <PostCard key={node.slug.current} post={node} />
      ))}
    </div>
  );
};

export default PostList;
