import React from 'react';
import './PostCard.css';
import { Link } from 'gatsby';

const PostCard = ({ post }) => {
  // Verifica se todos os dados necessários estão presentes antes de renderizar
  if (!post || !post.slug || !post.slug.current) {
    return (
      <div className="post-card post-card-error">
        <p>Erro ao carregar o post. Dados incompletos.</p>
      </div>
    );
  }

  const { title, excerpt, slug, mainImage } = post;

  return (
    <div className="post-card" id={`post-card-${slug.current}`} role="article">
      {mainImage && mainImage.asset && (
        <img
          src={mainImage.asset.url}
          alt={`Imagem para ${title || 'post sem título'}`}
          className="post-card-image"
          loading="lazy"
        />
      )}
      <h2 className="post-title">
        <Link to={`/blog/${slug.current}`} aria-label={`Leia mais sobre ${title || 'post sem título'}`}>
          {title || 'Título Indisponível'}
        </Link>
      </h2>
      <p className="post-excerpt">
        {excerpt || 'Descrição não disponível.'}
      </p>
      <Link to={`/blog/${slug.current}`} className="read-more" aria-label={`Leia mais sobre ${title || 'post sem título'}`}>
        Leia mais
      </Link>
    </div>
  );
};

export default PostCard;
