import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import './CategorySidebar.css'; // Certifique-se de que o CSS correto seja importado

const CategorySidebar = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityCategory {
        nodes {
          title
          slug {
            current
          }
        }
      }
    }
  `);

  // Verifique se os dados estão disponíveis e têm a estrutura esperada
  if (!data || !data.allSanityCategory || !Array.isArray(data.allSanityCategory.nodes)) {
    return <div>Erro ao carregar categorias.</div>;
  }

  const categories = data.allSanityCategory.nodes;

  // Verifica se há categorias para exibir
  if (categories.length === 0) {
    return <div>Nenhuma categoria disponível.</div>;
  }

  return (
    <aside className="category-sidebar">
      <h3>Categorias</h3>
      <ul>
        {categories.map(category => (
          <li key={category.slug.current || Math.random().toString(36).substr(2, 9)}>
            <Link to={`/blog/categoria/${category.slug.current}`} className="category-link">
              {category.title || 'Categoria Desconhecida'}
            </Link>
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default CategorySidebar;
