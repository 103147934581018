import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/Seo';
import BlogCategoryHeader from '../components/BlogCategoryHeader/blogCategoryHeader';
import PostList from '../components/PostList/postList';
import CategorySidebar from '../components/CategorySidebar/categorySidebar';
import Pagination from '../components/Pagination/pagination';
import Breadcrumbs from '../components/Breadcrumbs/breadcrumbs';

const BlogCategoryTemplate = ({ data, pageContext }) => {
  // Verificação de segurança para garantir que os dados existam
  const posts = data?.allSanityPost?.edges || [];
  const { categoryName, currentPage, numPages, categorySlug } = pageContext;

  // Construção dinâmica das migalhas de pão
  const crumbs = [
      { path: '/', label: 'Página Inicial' },
      { path: '/blog', label: 'Blog' },
      { path: `/blog/categoria/${categorySlug}`, label: categoryName }
  ];

  return (
      <Layout>
          <Seo title={`Categoria: ${categoryName}`} />
          <Breadcrumbs crumbs={crumbs} />
          <BlogCategoryHeader categoryName={categoryName} postCount={posts.length} />
          <div className="category-page-content">
              {posts.length > 0 ? (
                  <PostList posts={posts.map(edge => edge.node)} />
              ) : (
                  <p>Nenhum post encontrado nesta categoria.</p>
              )}
              <CategorySidebar />
          </div>
          {numPages > 1 && (
              <Pagination currentPage={currentPage} numPages={numPages} />
          )}
      </Layout>
  );
};

export const query = graphql`
query($categorySlug: String!) {
  allSanityPost(
    filter: { categories: { elemMatch: { slug: { current: { eq: $categorySlug } } } } }
    sort: { fields: publishedAt, order: DESC }
  ) {
    edges {
      node {
        title
        slug {
          current
        }
        excerpt
        publishedAt
      }
    }
  }
}
`;

export default BlogCategoryTemplate;